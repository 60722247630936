import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

import axios from "axios";
axios.defaults.baseURL = "https://bellboy.cleanb.life";

const SignOutButton = () => {
    const navigate = useNavigate();

    const handleSignOut = async () => {
        console.log("handleSignOut");
        try {
            const token = localStorage.getItem("token");
            const response = await axios.post("/api/v1/auth/user/signout", null, { headers: { "xc-auth": token } });
            // handle response
            localStorage.removeItem("token");
            navigate("/signin"); // 로그아웃 후 로그인 화면으로 이동
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Button variant="contained" color="secondary" onClick={handleSignOut}>
            Sign Out
        </Button>
    );
};

export default SignOutButton;
