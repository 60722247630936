import React, { useState, useEffect, useCallback } from "react";
import {
    InputAdornment,
    IconButton,
    Card,
    CardContent,
    Grid,
    TextField,
    Typography,
    Paper,
    List,
    ListItem,
    ListItemText,
    styled,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import _ from "lodash";

const StyledCard = styled(Card)(({ theme, type }) => ({
    margin: theme.spacing(2, 0),
    backgroundColor: type === "sales" ? "#f3e5f5" : "#e8f5e9",
    "& .MuiCardContent-root": {
        padding: theme.spacing(2, 3),
        textAlign: "left",
        overflowWrap: "break-word",
        wordWrap: "break-word",
        hyphens: "auto",
    },
}));

const StyledTextField = styled(TextField)({
    backgroundColor: "#fafac6",
    borderRadius: "8px",
});

const SearchComponent = () => {
    const [query, setQuery] = useState("");
    const [results, setResults] = useState({ sales_old: [], customalls: [] });
    const [timeoutId, setTimeoutId] = useState(null);

    const handleSearch = useCallback(async () => {
        if (query.trim().length >= 2) {
            const response = await axios.get(`https://kyc.cleanb.life/be/search?query=${query.trim()}`);
            if (response.status === 200) {
                setResults(response.data);
            }
        }
    }, [query]);

    const debouncedHandleSearch = useCallback(_.debounce(handleSearch, 300), [query]);

    useEffect(() => {
        if (query.length === 0) {
            setResults({ sales_old: ["No search"], customalls: ["No search"] });
        } else if (query.length < 2) {
            setResults({ sales_old: [], customalls: [] });
        } else {
            debouncedHandleSearch();
        }
        return () => {
            clearTimeout(timeoutId);
            debouncedHandleSearch.cancel();
        };
    }, [query, debouncedHandleSearch, timeoutId]);

    const handleChange = (e) => {
        setQuery(e.target.value);
        debouncedHandleSearch();
    };

    const renderCard = (data, idx, type) => {
        if (query.length === 0) {
            return (
                <StyledCard key={idx} type={type}>
                    <CardContent>
                        <Typography variant="body1">No search</Typography>
                    </CardContent>
                </StyledCard>
            );
        }
        return (
            <StyledCard key={idx} type={type}>
                <CardContent>
                    <Grid container spacing={0}>
                        {Object.entries(data).map(([key, value]) => {
                            let color = "black";
                            let fontWeight = "normal";
                            let bgColor = "";
                            if (key.includes("이름") || key.includes("자명")) {
                                fontWeight = "bold";
                            }
                            if (key.includes("휴대폰") || key.includes("전화")) {
                                color = "#0000FF";
                                fontWeight = "bold";
                            }
                            if (key.includes("이메일")) {
                                color = "#FF0000";
                                fontWeight = "bold";
                            }
                            if (key === "all_id") {
                                bgColor = "#f6f861";
                                fontWeight = "bold";
                            }

                            return (
                                <Grid key={key} item xs={3}>
                                    <List style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 0, paddingRight: 0 }}>
                                        <ListItem style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 0, paddingRight: 0 }}>
                                            <ListItemText
                                                sx={{
                                                    whiteSpace: "normal",
                                                    overflow: "hidden",
                                                    wordBreak: "break-all",
                                                    textOverflow: "ellipsis",
                                                }}
                                                style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 2, paddingRight: 2 }}
                                                primary={key}
                                                secondary={value || ""}
                                                primaryTypographyProps={{
                                                    // style: { color: color, fontWeight: fontWeight, backgroundColor: bgColor, fontSize: "0.6rem" },
                                                    style: {
                                                        color: "#555555",
                                                        fontWeight: "bold",
                                                        backgroundColor: "#cccccc",
                                                        fontSize: "0.7rem",
                                                    },
                                                }}
                                                secondaryTypographyProps={{
                                                    style: { color: color, fontWeight: fontWeight, backgroundColor: bgColor, fontSize: "0.8rem" },
                                                }}
                                            />
                                        </ListItem>
                                    </List>
                                </Grid>
                            );
                        })}
                    </Grid>
                </CardContent>
            </StyledCard>
        );
    };
    // sx={{ alignItems: "center", justifyContent: "center" }}
    return (
        <Grid container spacing={2} style={{ margin: "0.9em" }}>
            <Grid item xs={12} sx={{ alignItems: "center" }}>
                <Paper
                    component="form"
                    style={{ marginBottom: "0.9em" }}
                    sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "95%" }}
                >
                    <StyledTextField
                        variant="outlined"
                        label="검색할 고객 정보"
                        color="primary"
                        fullWidth
                        placeholder="고객정보를 입력해주세요..."
                        value={query}
                        onChange={handleChange}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setQuery("")}>{query ? <CloseIcon /> : <SearchIcon />}</IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Paper>
            </Grid>
            <Grid item xs={5.5}>
                <Typography variant="h6" sx={{ color: "#673ab7", fontWeight: "bold" }}>
                    OLD 주문데이터
                </Typography>
                {results.sales_old.length === 0 ? (
                    <StyledCard>
                        <CardContent>
                            <Typography variant="body1">No result</Typography>
                        </CardContent>
                    </StyledCard>
                ) : (
                    results.sales_old.map((data, idx) => renderCard(data, idx, "sales"))
                )}
            </Grid>
            <Grid item xs={6}>
                <Typography variant="h6" sx={{ color: "#388e3c", fontWeight: "bold" }}>
                    ALL 고객데이터
                </Typography>
                {results.customalls.length === 0 ? (
                    <StyledCard>
                        <CardContent>
                            <Typography variant="body1">No result</Typography>
                        </CardContent>
                    </StyledCard>
                ) : (
                    results.customalls.map((data, idx) => renderCard(data, idx, "customers"))
                )}
            </Grid>
        </Grid>
    );
};

export default SearchComponent;
