// src/SignInScreen.js

import React, { useState } from "react";
import { Button, TextField, Grid, Paper, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, IconButton } from "@mui/material";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import axios from "axios";
import { useNavigate } from "react-router-dom";

axios.defaults.baseURL = "https://bellboy.cleanb.life";

const SignInScreen = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogMessage, setDialogMessage] = useState("");
    const navigate = useNavigate();

    const handleSignIn = async (e) => {
        e.preventDefault();

        if (!email || !password) {
            // One or both fields are empty
            setDialogMessage("Please fill out all fields");
            setOpenDialog(true);
            return;
        }

        try {
            const response = await axios.post("/api/v1/auth/user/signin", { email: email, password: password });
            localStorage.setItem("token", response.data.token);
            // Manually submit the form to trigger the browser's autofill save
            // if (formRef.current) {
            //     formRef.current.dispatchEvent(new Event("submit", { cancelable: true }));
            // }
            navigate("/");
        } catch (error) {
            console.error(error);
            if (error.response && error.response.data && error.response.data.detail) {
                if (typeof error.response.data.detail === "string") {
                    setDialogMessage(error.response.data.detail);
                } else if (Array.isArray(error.response.data.detail)) {
                    // If the error detail is an object, we assume it has a msg property
                    setDialogMessage(error.response.data.detail.map((err) => err.msg).join("\n"));
                } else {
                    setDialogMessage("An unknown error occurred.");
                }
            } else {
                setDialogMessage("An unknown error occurred.");
            }
            setOpenDialog(true);
        }
        document.getElementById("password").setAttribute("autocomplete", "on");
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    return (
        <Grid container justifyContent="center">
            <Paper style={{ padding: "2em" }}>
                <h2>Sign In</h2>
                {/* Add a form element and attach the ref */}
                <form onSubmit={handleSignIn}>
                    <TextField
                        label="Email"
                        value={email}
                        name="email"
                        onChange={(e) => setEmail(e.target.value)}
                        margin="normal"
                        fullWidth
                        required
                    />
                    <TextField
                        label="Password"
                        type="password"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        margin="normal"
                        fullWidth
                        required
                        id="password"
                    />
                    <div style={{ display: "flex", justifyContent: "space-between", marginTop: "1em" }}>
                        <Button variant="contained" color="primary" type="submit">
                            Sign In
                        </Button>
                        <Button variant="outlined" color="secondary" onClick={() => navigate("/signup")}>
                            Sign Up
                        </Button>
                    </div>
                </form>

                {/* <Button variant="outlined" color="secondary" onClick={() => navigate("/changepassword")}>
                    Change Password
                </Button> */}
                <Dialog
                    open={openDialog}
                    onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title" style={{ display: "flex", alignItems: "center" }}>
                        <IconButton color="secondary">
                            <ErrorOutlineIcon />
                        </IconButton>
                        {"Error"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">{dialogMessage}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog} color="primary" autoFocus>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            </Paper>
        </Grid>
    );
};

export default SignInScreen;
