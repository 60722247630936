import React, { useMemo, useState, useEffect, useCallback } from "react";
import { DataGrid, GridToolbarContainer, GridToolbarDensitySelector } from "@mui/x-data-grid";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import debounce from "lodash/debounce";
import throttle from "lodash/throttle";

import {
    InputAdornment,
    IconButton,
    Card,
    CardContent,
    Stack,
    TextField,
    Typography,
    Paper,
    List,
    ListItem,
    ListItemText,
    styled,
} from "@mui/material";

// Function to render header with two lines
const renderHeaderWithTwoLines = (headerName) => <div style={{ whiteSpace: "pre-line", lineHeight: "1.2em" }}>{headerName}</div>;

// Function to create a column with common header rendering
const createColumn = (headerName, field, width, type = "string") => ({
    field,
    headerName,
    width,
    type,

    renderHeader: () => renderHeaderWithTwoLines(headerName),
});

const columnsCustInit = [
    createColumn("이름", "이름", 10 * 8 + 2), // Max Length * (average character width) + padding
    createColumn("결제전화", "결제전화", 16 * 6 + 2),
    createColumn("수신전화", "수신전화", 16 * 6 + 2),
    createColumn("성별", "성별", 4 * 5 + 2),
    createColumn("결제자", "결제자명", 10 * 8 + 2),
    createColumn("통합id", "all_id", 8 * 8 + 2, "number"),
    createColumn("구id", "old_id", 8 * 8 + 2, "number"),
    createColumn("신id", "new_id", 8 * 8 + 2, "number"),
    createColumn("별명", "별명", 10 * 5 + 2), //26
    createColumn("이메일", "이메일", 26 * 5 + 2), //34
    createColumn("유형", "유형", 6 * 5 + 4),
    createColumn("존재", "존재", 4 * 5 + 2),
    createColumn("mc", "mc", 4 * 5 + 2, "number"),
    // createColumn("결제주소1","결제주소1",20 * 5 + 2), //50 * 5 + 2,
    // createColumn("결제주소2","결제주소2",20 * 5 + 2),
    createColumn("결제주소", "결제주소", 72 * 5 + 8), //72 * 5 + 2,,
    createColumn("결제 우편번호", "결제우편번호", 8 * 8 + 2),
    createColumn("로그인id", "로그인id", 26 * 5 + 2), //34
    createColumn("소셜로그인", "소셜로그인", 6 * 5 + 2),
    createColumn("카카오사용", "카카오사용", 4 * 5 + 2),
    createColumn("출생년도", "출생년도", 19 * 5 + 2),
    createColumn("최종로그인일시", "최종로그인일시", 19 * 8 + 2),
    createColumn("가입일시", "가입일시", 19 * 8 + 2),
    createColumn("sixshop 가입일", "user_registered_old", 19 * 8 + 2),
    createColumn("WC가입일시", "user_registered_new", 19 * 8 + 2),
    createColumn("display_name", "display_name", 12 * 5 + 2), //27
    // createColumn("수신우편번호_임시","수신우편번호_임시",5 * 5 + 2),
    // createColumn("수신자명","수신자명",14 * 5 + 2),
    // createColumn("수신주소_임시","수신주소_임시",67 * 5 + 2),
    createColumn("나이", "나이", 5 * 5 + 2, "number"),
    createColumn("회원 상태", "회원상태", 4 * 5 + 2),
    createColumn("최종정보변경일시", "최종정보변경일시", 19 * 8 + 2),
    createColumn("행정구분", "행정구분", 11 * 8 + 2),
];

const columnsSubscrInit = [
    createColumn("시간순\n회차", "회차_시간순", 1 * 8 + 2),
    // createColumn("가상구독회차_시간별","가상구독회차_시간별",17 * 5 + 2),
    // createColumn("가상구독회차_일별","가상구독회차_일별",17 * 5 + 2),
    createColumn("일자순\n회차", "회차_날짜순", 1 * 8 + 2),
    createColumn("주문id", "주문id", 12 * 5 + 2), //20
    createColumn("통합\n고객id", "all_id", 12 * 5 + 2),
    createColumn("결제자", "결제자명", 10 * 5 + 2),
    createColumn("수신자", "수신자명", 10 * 5 + 2),
    createColumn("결제금액", "결제금액", 10 * 5 + 2),
    createColumn("세금", "세금", 10 * 5 + 2),
    createColumn("cb주문종류", "cb주문종류", 6 * 5 + 3),
    createColumn("정산", "정산", 2 * 5 + 2),
    createColumn("결제일시", "결제일시", 18 * 8 + 2),
    createColumn("취소 일시", "취소일시", 4 * 5 + 2),
    createColumn("결제 방법", "결제방법", 8 * 5 + 2), //
    // createColumn("wc상품종류","wc상품종류",10 * 5 + 2),
    createColumn("상품id", "상품id", 6 * 5 + 2),
    // createColumn("상품명", "상품명", 16 * 8 + 2),
    createColumn("주문상품명", "주문상품명", 10 * 8 + 2),
    createColumn("wc상태", "wc상태", 11 * 8 + 2), //19
    createColumn("구독id", "구독id", 8 * 5 + 2),
    createColumn("수량", "수량", 2 * 5 + 2),
    createColumn("옵션 색상", "색상", 4 * 5 + 2),
    createColumn("옵션 주기", "주기", 4 * 5 + 2),
    createColumn("옵션 크기", "크기", 4 * 5 + 2),
    createColumn("옵션 두께", "두께", 4 * 5 + 2),
    createColumn("이용권수", "이용권수", 2 * 5 + 2),
    createColumn("주문일시", "주문일시", 4 * 5 + 2), //25
    createColumn("수신 전화", "수신전화", 13 * 8),
    createColumn("수신 이메일", "수신이메일", 10 * 5 + 2), //40
    createColumn("수신 우편번호", "수신우편번호", 8 * 8 + 2),
    createColumn("수신주소", "수신주소", 20 * 5 + 2), //67
    // createColumn("수신주소1","수신주소1",53 * 5 + 2),
    // createColumn("수신주소2","수신주소2",49 * 5 + 2),
    createColumn("수신 출입비번", "수신출입비번", 20 * 5 + 2), //70 * 5 + 2),
    createColumn("결제 전화", "결제전화", 13 * 8 + 2),
    createColumn("결제 이메일", "결제이메일", 8 * 5 + 2), //31
    createColumn("결제 우편번호", "결제우편번호", 5 * 4 + 2),
    createColumn("결제주소", "결제주소", 20 * 5 + 2), //66
    // createColumn("결제주소1","결제주소1",50 * 5 + 2),
    // createColumn("결제주소2","결제주소2",37 * 5 + 2),

    createColumn("승인id", "승인id", 8 * 5 + 2),
    // createColumn("txid","txid",42 * 5 + 2),
    // createColumn("_pafw_txnid","_pafw_txnid",19 * 5 + 2),
    createColumn("결제유형", "결제유형", 8 * 5 + 2), //21
    createColumn("카드유형", "카드유형", 4 * 5 + 2),
    createColumn("결제카드", "결제카드", 9 * 5 + 2),
    createColumn("pg 결제일시", "pg결제일시", 25 * 5 + 2),
    createColumn("pg\n결제금액", "pg결제금액", 9 * 7 + 2),
    createColumn("구독\n갱신", "_subscription_renewal", 5 * 5 + 2),
    createColumn("결제 장비", "결제장비", 10 * 5 + 2),
    // createColumn("_order_version", "_order_version", 5 * 5 + 2),
    createColumn("카트\n세후금액", "카트세후금액", 10 * 7 + 2),
    createColumn("카트\n부가세", "카트부가세", 10 * 7 + 2), //18
    createColumn("카트\n할인금액", "카트할인금액", 10 * 7 + 2),
    // createColumn("카트할인세금","카트할인세금",7 * 7 + 2),
    // createColumn("배송비","배송비",7 * 5 + 2),
    createColumn("상품\n수량", "상품수량", 4 * 7 + 2),
    // createColumn("상품할인전금액","상품할인전금액",8 * 7 + 2),
    // createColumn("상품세전금액","상품세전금액",8 * 7 + 2),
    // createColumn("상품부가세","상품부가세",7 * 7 + 2),
    createColumn("적용쿠폰", "적용쿠폰", 19 * 7 + 2),
    // createColumn("상품세전환불금","상품세전환불금",9 * 7 + 2),
    // createColumn("상품환불세금","상품환불세금",8 * 7 + 2),
    // createColumn("상품세후환불금","상품세후환불금",9 * 7 + 2),
    // createColumn("상품환불수량","상품환불수량",4 * 7 + 2),
    createColumn("세후\n순매출금액", "세후순결제금액", 10 * 7 + 2),
    createColumn("카트\n환불수량", "카트환불수량", 4 * 7 + 2),
];

// 첫 번째 DataGrid의 헤더 스타일
const CustDataGrid = styled(DataGrid)(({ theme }) => ({
    "& .MuiDataGrid-columnHeaders": {
        backgroundColor: "#e8f5e9",
    },
    fontSize: "11px",
}));

// 두 번째 DataGrid의 헤더 스타일
const SubscrDataGrid = styled(DataGrid)(({ theme }) => ({
    "& .MuiDataGrid-columnHeaders": {
        backgroundColor: "#f3e5f5",
    },
    "& .MuiDataGrid-footerContainer": {
        justifyContent: "start !important", // 페이지네이션을 왼쪽으로 정렬
    },
    fontSize: "10px",
}));
const CustSubscrSearch = () => {
    const [query, setQuery] = useState("");
    const [results, setResults] = useState({ subscriptalls: [], customalls: [] });
    const [firstWord, setFirstWord] = useState("");
    const [fetchedResults, setFetchedResults] = useState({ subscriptalls: [], customalls: [] });
    const [filteredResults, setFilteredResults] = useState({ subscriptalls: [], customalls: [] });

    const handleSearch = async (searchQuery) => {
        const pattern = new RegExp("[-_@ &]|[가-힣]{2,}|[\\w]{3,}");
        if (searchQuery && searchQuery.trim().length >= 2 && pattern.test(searchQuery.trim())) {
            try {
                const response = await axios.get(`https://kyc.cleanb.life/be/newsearch?query=${searchQuery.trim()}`);
                if (response.status === 200) {
                    setFetchedResults(response.data);
                    setFilteredResults(response.data);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
    };

    const debouncedHandleSearch = useCallback(debounce(handleSearch, 10), []);
    // const throttledHandleSearch = useCallback(throttle(handleSearch, 500), []);

    const handleChange = (e) => {
        const newQuery = e.target.value;
        setQuery(newQuery);
        if (newQuery.trim() === "") {
            setFilteredResults({ subscriptalls: [], customalls: [] });
            return;
        }

        const words = newQuery
            .trim()
            .split(" ")
            .filter((word) => word.length > 0);

        if (words.length === 0) {
            setResults(fetchedResults);
            return;
        }

        const newFirstWord = words[0];
        if (newFirstWord !== firstWord) {
            setFirstWord(newFirstWord);
            // throttledHandleSearch(newFirstWord); // 쓰로틀링 적용
            debouncedHandleSearch(newFirstWord);
        } else {
            filterResultsForMultipleWords(words);
        }
    };

    const filterResultsForMultipleWords = (words) => {
        const filteredSubscriptalls = fetchedResults.subscriptalls.filter((row) =>
            words.every((word) => Object.values(row).some((value) => value != null && value.toString().toLowerCase().includes(word.toLowerCase())))
        );

        const filteredCustomalls = fetchedResults.customalls.filter((row) =>
            words.every((word) => Object.values(row).some((value) => value != null && value.toString().toLowerCase().includes(word.toLowerCase())))
        );

        setFilteredResults({
            subscriptalls: filteredSubscriptalls,
            customalls: filteredCustomalls,
        });
    };

    // const handleClear = () => {
    //     setQuery("");
    //     setResults({ subscriptalls: [], customalls: [] });
    // };
    const handleClear = () => {
        setQuery("");
        setFilteredResults({ subscriptalls: [], customalls: [] }); // Clearing filtered results as well
    };
    return (
        <div>
            <TextField
                sx={{ width: "100%", marginTop: "18px", marginLeft: "20px" }}
                variant="outlined"
                label="검색할 고객 정보"
                color="primary"
                fullWidth
                placeholder="고객정보를 입력해주세요..."
                value={query}
                onChange={handleChange}
                InputProps={{
                    style: {
                        backgroundColor: "#fafac6",
                        borderRadius: "8px",
                        width: "800px",
                    },
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={handleClear}>{query ? <CloseIcon /> : <SearchIcon />}</IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            <NewSearchComponent results={filteredResults} />
        </div>
    );
};

export default CustSubscrSearch;

const NewSearchComponent = React.memo(({ results }) => {
    const [columnsCust, setColumnsCust] = useState(columnsCustInit);
    const [columnsSubscr, setColumnsSubscr] = useState(columnsSubscrInit);

    const handleCellClick = (params) => {
        const updatedColumns = columnsSubscr.map((col) => {
            // 특정 필드에 대한 너비 변경 로직
            if (col.field === "결제주소" && params.field === "결제주소") {
                return { ...col, width: col.width === 20 * 5 + 2 ? 72 * 5 + 2 : 20 * 5 + 2 }; // 예시: 'id' 필드의 너비 변경
            } else if (col.field === "수신주소" && params.field === "수신주소") {
                return { ...col, width: col.width === 20 * 5 + 2 ? 72 * 5 + 2 : 20 * 5 + 2 }; // 예시: 'name' 필드의 너비 변경
            } else if (col.field === "주문상품명" && params.field === "주문상품명") {
                return { ...col, width: col.width === 10 * 8 + 2 ? 42 * 5 + 2 : 10 * 8 + 2 }; // 예시: 'name' 필드의 너비 변경
            } else if (col.field === "결제이메일" && params.field === "결제이메일") {
                return { ...col, width: col.width === 8 * 5 + 2 ? 34 * 5 + 2 : 8 * 5 + 2 }; // 예시: 'name' 필드의 너비 변경
            } else if (col.field === "수신이메일" && params.field === "수신이메일") {
                return { ...col, width: col.width === 10 * 5 + 2 ? 34 * 5 + 2 : 10 * 5 + 2 }; // 예시: 'name' 필드의 너비 변경
            } else if (col.field === "결제유형" && params.field === "결제유형") {
                return { ...col, width: col.width === 8 * 5 + 2 ? 21 * 7 + 2 : 8 * 5 + 2 }; // 예시: 'name' 필드의 너비 변경
            } else if (col.field === "결제방법" && params.field === "결제방법") {
                return { ...col, width: col.width === 8 * 5 + 2 ? 18 * 5 + 2 : 8 * 5 + 2 }; // 예시: 'name' 필드의 너비 변경
            } else if (col.field === "취소일시" && params.field === "취소일시") {
                return { ...col, width: col.width === 4 * 5 + 2 ? 18 * 5 + 2 : 4 * 5 + 2 }; // 예시: 'name' 필드의 너비 변경
            } else if (col.field === "주문일시" && params.field === "주문일시") {
                return { ...col, width: col.width === 4 * 5 + 2 ? 18 * 5 + 2 : 4 * 5 + 2 }; // 예시: 'name' 필드의 너비 변경
            }

            return col;
        });
        setColumnsSubscr(updatedColumns);
    };

    return (
        <Stack
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            spacing={1}
            sx={{ width: "100%", marginTop: "18px", marginLeft: "20px" }}
        >
            <Paper
                component="form"
                style={{ marginBottom: "0.9em" }}
                sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
            ></Paper>
            <Typography variant="h6">고객데이터</Typography>
            <CustDataGrid
                hideFooter={true}
                columnMenu={true}
                getRowId={(row) => row.id}
                rows={results.customalls}
                columns={columnsCust}
                pageSize={5}
                // checkboxSelection
                disableSelectionOnClick
                style={{ width: "100%" }}
                density="compact"
            />
            <div style={{ width: "2000px" }}></div>
            <Typography variant="h6">구독 데이터</Typography>
            <SubscrDataGrid
                disableColumnMenu={true}
                getRowId={(row) => row.id}
                rows={results.subscriptalls}
                columns={columnsSubscr}
                pageSize={30}
                onCellClick={handleCellClick}
                // checkboxSelection
                disableSelectionOnClick
                style={{ width: "100%" }}
                // slots={{
                //     toolbar: CustomToolbar,
                // }}
                density="compact"
                columnBuffer={5}
                columnThreshold={5}
            />
            <div style={{ width: "2000px" }}></div>
        </Stack>
    );
});
