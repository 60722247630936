import React, { useEffect } from "react";
import {
    Box,
    CssBaseline,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Drawer,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    styled,
    useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import MailIcon from "@mui/icons-material/Mail";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SearchComponent from "./SearchComponent";
import CustSubscrSearch from "./NewSearchComponent";
import { ThemeProvider, createTheme } from "@mui/material/styles"; // 변경된 임포트
import { useNavigate } from "react-router-dom";
import SignInScreen from "./SignInScreen";
import SignOutButton from "./SignOutButton";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import RecyclingIcon from "@mui/icons-material/Recycling";
import Tooltip from "@mui/material/Tooltip";

const drawerWidth = 240;
const collapsedDrawerWidth = 60;

// SearchComponent에 사용된 스타일을 기반으로 테마 생성
const theme = createTheme({
    // ... 테마 설정 ...
    palette: {
        // 예시: 전역 색상 설정
        primary: {
            main: "#673ab7", // 다크 퍼플
        },
        secondary: {
            main: "#388e3c", // 다크 그린
        },
        // ... 기타 전역 색상 설정 ...
    },
});

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(({ theme }) => ({
    flexGrow: 1,
    padding: theme.spacing(0),
    marginLeft: collapsedDrawerWidth, // Drawer가 접혔을 때의 폭으로 고정
    transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
}));

const AppBarStyled = styled(AppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
}));

function App() {
    const [open, setOpen] = React.useState(false);
    const [selectedMenu, setSelectedMenu] = React.useState("구독내역 검색");

    const theme = useTheme(); // useTheme 훅을 사용하여 theme 객체 얻기
    const navigate = useNavigate();
    // const isSignedIn = localStorage.getItem("token");
    const isSignedIn = true;

    useEffect(() => {
        if (!isSignedIn) {
            navigate("/signin");
        }
    }, [isSignedIn, navigate]);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleMenuClick = (menu) => {
        setSelectedMenu(menu);
    };

    return isSignedIn ? (
        <ThemeProvider theme={theme}>
            <Box sx={{ display: "flex" }}>
                <CssBaseline />
                <AppBarStyled position="fixed" open={open}>
                    <Toolbar sx={{ justifyContent: "space-between" }}>
                        <Typography
                            variant="h6"
                            noWrap
                            sx={{
                                transition: theme.transitions.create("margin", {
                                    easing: theme.transitions.easing.sharp,
                                    duration: theme.transitions.duration.leavingScreen,
                                }),
                                marginLeft: open ? `${drawerWidth}px` : `50px`, // Drawer 상태에 따라 marginLeft 조정
                            }}
                        >
                            {selectedMenu}
                        </Typography>
                        <SignOutButton />
                    </Toolbar>
                </AppBarStyled>
                <Drawer
                    sx={{
                        width: open ? drawerWidth : collapsedDrawerWidth,
                        flexShrink: 0,
                        "& .MuiDrawer-paper": {
                            width: open ? drawerWidth : collapsedDrawerWidth,
                            boxSizing: "border-box",
                        },
                    }}
                    variant="permanent" // 'persistent' 대신 'permanent' 사용
                    anchor="left"
                    open={open}
                >
                    <DrawerHeader>
                        <IconButton onClick={open ? handleDrawerClose : handleDrawerOpen}>
                            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                        </IconButton>
                    </DrawerHeader>
                    <List>
                        {["구독내역 검색", "고객/과거매출내역 검색", "TBD2"].map((text, index) => (
                            <ListItem key={text} disablePadding>
                                <ListItemButton
                                    onClick={() => handleMenuClick(text)}
                                    sx={{
                                        padding: 0, // 내부 패딩 제거
                                        "& .MuiListItemIcon-root": {
                                            minWidth: "auto", // 아이콘 최소 너비 제거 또는 줄임
                                            marginRight: "8px", // 아이콘과 텍스트 사이 여백 조정
                                            marginLeft: "8px",
                                        },
                                    }}
                                >
                                    <Tooltip title={text}>
                                        <ListItemIcon>
                                            {index === 0 ? <RecentActorsIcon /> : index === 1 ? <RecyclingIcon /> : index === 2 ? <MailIcon /> : null}
                                        </ListItemIcon>
                                    </Tooltip>

                                    {open && <ListItemText primary={text} />}
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Drawer>
                <Main open={open} sx={{ marginLeft: 0 }}>
                    <DrawerHeader />
                    {selectedMenu === "구독내역 검색" ? <CustSubscrSearch /> : null}
                    {selectedMenu === "고객/과거매출내역 검색" ? <SearchComponent /> : null}
                </Main>
            </Box>
        </ThemeProvider>
    ) : (
        <SignInScreen />
    );
}

export default App;
